/**
 * Cookie consent bar
 *
 * Listen for acceptance via document.addEventListener('cookies-accepted')
 */
module.exports = class CookieConsent {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;

    const cookieValue = this.getCookieValue();
    if (cookieValue === '') {
      this.renderCookie();
      return;
    }
    if (cookieValue === '1') {
      this.dispatchCookieConsent();
    }
  }

  dispatchCookieConsent() {
    const event = document.createEvent('Event');
    event.initEvent('cookies-accepted', true, true);
    document.dispatchEvent(event);
  }

  /**
   * get cookie getcookie
   *
   * @returns {string}
   */
  getCookieValue() {
    return document.cookie.replace(/(?:(?:^|.*;\s*)cookieAccepted\s*\=\s*([^;]*).*$)|^.*$/, '$1');
  }

  /**
   * set cookie value
   */
  setAcceptCookie() {
    document.cookie = 'cookieAccepted=1;max-age=31536000;path=/'; // one year
  }

  setDeclineCookie() {
    document.cookie = 'cookieAccepted=0;max-age=86400;path=/'; // one month
  }

  /**
   * renderCookiebar
   */
  renderCookie() {
    this.$element.classList.remove('u-hidden');
    this.$element.querySelector('.js-accept-cookie').addEventListener('click', () => {
      this.$element.outerHTML = '';
      this.setAcceptCookie();
      this.dispatchCookieConsent();
    });

    this.$element.querySelector('.js-decline-cookie').addEventListener('click', () => {
      this.$element.outerHTML = '';
      this.setDeclineCookie();
    });
  }
}
