/**
 * Social Share
 *
 * Expands and collapses a content section on click
 *
 */
import {isMobileOS} from '../libs/clientDetection';

module.exports = class SocialShare {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.toggleWhatsappShare();
  }

  /**
   * Check if the device is mobile/tablet
   */
  toggleWhatsappShare() {
    const whatsAppButton = this.$element.getElementsByClassName('js-whatsapp');
    if (whatsAppButton.length > 0 && isMobileOS()) {
      whatsAppButton[0].style.setProperty("display", "inline-block", "important")
    }
  }
}