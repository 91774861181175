import flatpickr from 'flatpickr';
import cloneDeep from 'lodash.cloneDeep';
import {Dutch} from "flatpickr/dist/l10n/nl.js";
import labelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin';

const datePickerDefaults = {
  locale: Dutch,
  altInput: true,
  altFormat: "j F Y",
  dateFormat: "d-m-Y",
  time_24hr: true,
  plugins: [new labelPlugin({})],
};
// Change first to of the week to Sunday
datePickerDefaults.locale.firstDayOfWeek = 0;

/**
 * Debounce
 */
function debounce(callback, wait, context = this) { // jshint ignore:line
  let timeout = null;
  let callbackArgs = null;

  const later = () => callback.apply(context, callbackArgs);

  return function () {
    callbackArgs = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function setDatePicker(field, additions = {}) {
  const defaults = cloneDeep(datePickerDefaults);
  const settings = Object.assign(defaults, additions);
  const min = field.getAttribute('min');
  const max = field.getAttribute('max');
  if (min) settings.minDate = min;
  if (max) settings.maxDate = max;
  if (field.dataset.time === 'true') {
    settings.enableTime = true;
    settings.dateFormat = settings.dateFormat + ' H:i';
    settings.altFormat = settings.altFormat + ' H:i';
  }
  return flatpickr(field, settings);
}

/**
 * Filter unique items in an array of objects
 *
 * @param {Array} arr
 * @param {String} key to filter for
 * @return {Array}
 */
function uniqueObjects(arr, key) {
  if (typeof arr !== 'object' || typeof key !== 'string') return arr;
  if (arr.length < 2) return arr;
  var unique = {};
  var distinct = [];
  for (var i in arr) {
    if (!arr[i][key]) continue;
    if (typeof (unique[arr[i][key]]) == "undefined") {
      distinct.push(arr[i]);
    }
    unique[arr[i][key]] = 0;
  }
  return distinct;
}

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
function findOne(haystack, arr) {
  return arr.some((item) => {
    return haystack.indexOf(item) >= 0;
  });
};

/**
 * Load Google Maps
 */
function loadGoogleMaps(params) {
  return new Promise((resolve, reject) => {
    // Reject the promise after a timeout
    var timeoutId = setTimeout(function () {
      window.googleMapsCallback = function () { } // Set the on load callback to a no-op
      reject(new Error('Could not load the Google Maps API'))
    }, 7000)

    // Hook up the on load callback
    window.googleMapsCallback = function () {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      resolve(window.google.maps)
      delete window.googleMapsCallback;
    }

    const scriptTag = document.createElement('script');
    scriptTag.setAttribute("type", "text/javascript");
    scriptTag.setAttribute("src", "https://maps.googleapis.com/maps/api/js?callback=googleMapsCallback&key=AIzaSyAf7lyK6lzLxacbGOQM53yni6_yP5T9lrs&libraries=places");
    document.body.appendChild(scriptTag);
  });
}

/**
 * Create a unique ID.
 * Don't use for security implementations, this creates a uniquish ID, not totally unique.
 */
function getUniqueId() {
  return (new Date().getTime()).toString(24)
}

export {
  debounce,
  setDatePicker,
  uniqueObjects,
  loadGoogleMaps,
  findOne,
  getUniqueId,
}