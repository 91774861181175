/**
 * Filters
 * *
 */
module.exports = class Filters {
  constructor($element) {
    if (!($element instanceof HTMLFormElement)) return;
    this.$element = $element;
    // Add listener to parent and delegate events
    this.initForm();
  }
  initForm() {
    Array.prototype.slice.call(this.$element.querySelectorAll('input,select')).forEach(inputField => {
      inputField.addEventListener("change", this.setFilters.bind(this), false);
    });
  }
  /**
   * Set filter in the url parameters and redirect to that url
   */
  setFilters(event) {
    let params = new URLSearchParams();
    // Find the form
    const form = document.forms[this.$element.name];
    const multiselectFilters = {};
    for (let index = 0; index < form.elements.length; index++) {
      const element = form.elements[index];
      // if elements is multiselect
      if (element.getAttribute('role') === 'option') {
        if (element.checked) {
          if (element.name in multiselectFilters) {
            multiselectFilters[element.name].push(element.value);
          } else {
            multiselectFilters[element.name] = [element.value];
          }
        }
      } else if (element.value !== '') {
        params.set(element.name, element.value);
      }
    }

    // Add all multiselectfilters
    Object.keys(multiselectFilters).forEach(filter => {
      params.set(filter, multiselectFilters[filter].join(','));
    });
    window.location.href = `${window.location.pathname}?${params}`;
  event.preventDefault();
  }
}
