import {setDatePicker} from '../libs/utils';

/**
 * Date Range
 *
 * Non-mobile browser consistent experience for updating date ranges
 * Uses https://flatpickr.js.org
 */
module.exports = class DateRange {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.$startEl = this.$element.querySelector('.date-range__start');
    this.$endEl = this.$element.querySelector('.date-range__end');
    if (!(this.$startEl instanceof HTMLElement) || !(this.$endEl instanceof HTMLElement)) return;
    this.initDateRange();
  }

  /**
   * Initiate flatpicker
   */
  initDateRange() {
    // Save initial values to set back on reset
    this.initialRangeStart = this.$startEl.getAttribute('min');
    this.initialRangeEnd = this.$endEl.getAttribute('max');

    this.startPicker = setDatePicker(this.$startEl, {
      // Add event listener for form reset
      'onReady': (dateObj, dateStr, instance) => {
        instance.input.form.addEventListener('reset', this.resetEndDate.bind(this));
      },
      // Update enddate min value on change
      'onChange': this.handleStartChange.bind(this),
    });
    this.endPicker = setDatePicker(this.$endEl, {
      // Add event listener for form reset
      'onReady': (dateObj, dateStr, instance) => {
        instance.input.form.addEventListener('reset', this.resetStartDate.bind(this));
      },
      // Update startdate max value on change
      'onChange': this.handleEndChange.bind(this),
    });
  }

  /**
   * Handle Start Change
   *
   * @param {Array} selectedDates Date objects selected by the user.
   * @param {String} dateStr string representation of the latest selected Date
   * @param {Object} instance flatpicker instance
   */
  handleStartChange(selectedDates, dateStr, instance) {
    this.$endEl.setAttribute('min', dateStr);
    this.endPicker.set('minDate', dateStr);
  }

  /**
   * Handle End Change
   *
   * @param {Array} selectedDates Date objects selected by the user.
   * @param {String} dateStr string representation of the latest selected Date
   * @param {Object} instance flatpicker instance
   */
  handleEndChange(selectedDates, dateStr, instance) {
    this.$startEl.setAttribute('max', dateStr);
    this.startPicker.set('maxDate', dateStr);
  }

  /**
   * Reset Start date
   */
  resetStartDate() {
    if (!this.startPicker.element.value) return;
    if (this.initialRangeStart) {
      this.$endEl.setAttribute('min', this.initialRangeStart);
    }
    // Null is default, the same as an undefined attribute
    this.endPicker.set('minDate', this.initialRangeStart);
  }

  resetEndDate() {
    if (!this.endPicker.element.value) return;
    if (this.initialRangeStart) {
      this.$startEl.setAttribute('max', this.initialRangeEnd);
    }
    // Null is default, the same as an undefined attribute
    this.startPicker.set('maxDate', this.initialRangeEnd);
  }
}