'use strict';
require('./libs/polyfills');

let Components = {};

// import modules into the list of Components.
Components.Article = require('./components/Article');
Components.Calendar = require('./components/Calendar');
Components.CharCounter = require('./components/CharCounter');
Components.CookieConsent = require('./components/CookieConsent');
Components.DateRange = require('./components/DateRange');
Components.DropdownToggle = require('./components/DropdownToggle');
Components.Filters = require('./components/Filters');
Components.Form = require('./components/Form');
Components.Header = require('./components/Header');
Components.MenuToggle = require('./components/menuToggle');
Components.PageFeedback = require('./components/PageFeedback');
Components.Toggle = require('./components/Toggle');
Components.ScrollHeader = require('./components/ScrollHeader');
Components.Slider = require('./components/Slider');
Components.SocialShare = require('./components/SocialShare');
Components.VideoLoader = require('./components/VideoLoader');
Components.SearchForm = require('./components/SearchForm');


// App
let ProtestantseKerk = function ProtestantseKerk() {

  let singletons = (function () {
    let registered = [];

    function isRegistered(componentName) {
      return registered.indexOf(componentName) > -1;
    }

    function register(componentName) {
      registered.push(componentName);
    }

    return {
      isRegistered: isRegistered,
      register: register,
    };

  }());

  function initialiseComponent($component, inputBehaviour = null) {
    let behaviour = inputBehaviour ? inputBehaviour : $component.getAttribute('data-behaviour');

    // When present, data-behaviour contains a space-separated list of handlers for that component
    let handlers = behaviour.trim().split(' ');
    handlers.forEach(function (handler) {
      if (!singletons.isRegistered(handler)) {
        if (!!Components[handler] && typeof Components[handler] === 'function') {
          new Components[handler]($component, window, window.document);
          if (Components[handler].isSingleton) {
            singletons.register(handler);
          }
        }
      }
    });
    $component.dataset.behaviourInitialised = true;
  }

  let components = document.querySelectorAll('[data-behaviour]');
  if (components) {
    [].forEach.call(components, (el) => initialiseComponent(el));
  }

  if ('MutationObserver' in window) {
    let observer = new MutationObserver(() => {
      let components = document.querySelectorAll('[data-behaviour]:not([data-behaviour-initialised])');
      if (components) {
        [].forEach.call(components, (el) => initialiseComponent(el));
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
  }
};
new ProtestantseKerk();