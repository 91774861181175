import makeAJAXRequest from '../libs/makeAJAXRequest';

/**
 * FORMS
 * *
 */

module.exports = class Form {
  constructor($element) {
    if (!($element instanceof HTMLFormElement)) return;
    this.$form = $element;
    this.$submitButton = this.$form.querySelector('button, input[type=submit]');
    // Add listener to parent and delegate events
    if (this.$form.dataset.submitType === 'ajax') {
      this.$form.addEventListener('bouncerFormValid', this.sendViaAjax.bind(this));
      this.messageBoxClass = 'form__help form__help--block'
      this.messageBox = document.createElement("DIV");
      this.messageBox.className = this.messageBoxClass;
      this.$form.appendChild(this.messageBox);
    }
  }

  sendViaAjax(event) {

    event.preventDefault();
    this.hideFormMessage()
    const data = this.getFormData();
    if (!data) {
      this.appendFormMessage('Formulier kon niet verzonden worden, geen data om te versturen. Vul het formulier in om het te kunnen versturen');
      return;
    }
    this.setLoadState();
    makeAJAXRequest(this.$form.action, {
        method: this.$form.method || 'POST',
        data: data
      })
      .then(() => {
        this.removeLoadState();
        this.appendFormMessage('Bericht verzonden! Bedankt voor uw inzending.', 'success');
        this.resetForm();
      })
      .catch((error) => {
        this.removeLoadState();
        console.error('Something went wrong', error);
        const errorMessage = error.message || 'Oeps er ging iets mis';
        this.appendFormMessage(errorMessage);
      })
  }

  resetForm() {
    this.$form.reset();
  }

  setLoadState() {
    if (this.$submitButton) {
      this.$submitButton.classList.add('is-loading');
      this.$submitButton.disabled = true;
    }
  }

  removeLoadState() {
    if (this.$submitButton) {
      this.$submitButton.classList.remove('is-loading');
      this.$submitButton.disabled = false;
    }
  }

  hideFormMessage() {
    this.messageBox.style.display = 'hide';
  }

  showFormMessage() {
    this.messageBox.style.display = 'block';
  }

  appendFormMessage(message = '', type = 'error') {
    this.messageBox.innerText = message;
    this.messageBox.className = this.messageBoxClass + ' is-' + type;
    this.showFormMessage();
  }

  getFormData() {
    let fields = [].filter.call(this.$form.elements, function (el) {
        //Allow only elements that don't have the 'checked' property
        //Or those who have it, and it's checked for them.
        return (['checkbox', 'radio'].indexOf(el.type) === -1) || el.checked;
        //Practically, filter out checkboxes/radios which aren't checekd.
      })
      //Delete Nameless elements
      .filter(function (el) {
        return !!el.name;
      })
      //Delete unchecked elements
      .filter(function (el) {
        return !(el.type === "checkbox" && !el.checked === true);
      });
      return fields.reduce(function(acc, el) {
        acc[el.name] = el.value;
        return acc;
      });
  }
}
