module.exports = class DropdownToggle {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.$parent = $element.parentNode;
    // Target is a sibling of the toggler
    this.$target = this.$parent.querySelector('#' + this.$element.getAttribute('aria-controls'));
    this.$targetFocus = this.$target.querySelector('li:first-child a, input');
    this.openEvent = new CustomEvent("openDropdown");
    this.closeEvent = new CustomEvent("closeDropdown");

    if (!(this.$target instanceof HTMLElement)) return;

    // Trigger click to open an menu
    this.$element.addEventListener('click', this.openDropdown.bind(this));
  }

  closeOnClickOutside(event) {
    if (!this.$target.contains(event.target)) this.closeDropdown();
  }

  handleKeypress(event) {
    // If delete or backspace
    if (event.keyCode == 27 || event.keyCode == 46) this.closeDropdown();

    // Down or right Arrow move item down
    if (event.keyCode == 40 || event.keyCode == 39) {
      const parentMenuItem = event.target.closest('.c-dropdown__menu__item');
      if (parentMenuItem && parentMenuItem.nextElementSibling) {
        parentMenuItem.nextElementSibling.querySelector('a, input').focus();
      }
      event.preventDefault();
    }

    // Up or left Arrow move item up
    if (event.keyCode == 38 || event.keyCode == 37) {
      const parentMenuItem = event.target.closest('.c-dropdown__menu__item');
      if (parentMenuItem && parentMenuItem.previousElementSibling) {
        parentMenuItem.previousElementSibling.querySelector('a, input').focus();
      }
      event.preventDefault();
    }
  }

  openDropdown() {
    this.$parent.classList.add('has-open-dropdown');
    this.$target.classList.remove('is-collapsed');
    this.$element.setAttribute('aria-expanded', 'true');
    if (this.$targetFocus) {
      // Focus after ending animation
      setTimeout(() => {
        this.$targetFocus.focus();
      }, 200);
    }

    // Add Close Event Listeners
    document.addEventListener('keydown', this.handleKeypress.bind(this), true);
    this.$element.dispatchEvent(this.openEvent);
    document.addEventListener('click', this.closeOnClickOutside.bind(this), true);
  }

  closeDropdown() {
    this.$parent.classList.remove('has-open-dropdown');
    this.$target.classList.add('is-collapsed');
    this.$element.setAttribute('aria-expanded', 'false');

    // Remove close Event Listeners
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('click', this.closeOnClickOutside);
    this.$element.dispatchEvent(this.closeEvent);
  }
}