/**
 * PAGE FEEDBACK
 *
 * Show if you like or dislike te page
 * Visitor can like/dislike a page and optional leave a comment
 *
 */

import {getUniqueId} from '../libs/utils';
import {GetBrowser, getScreenSize, getOS} from '../libs/clientDetection';
import makeAJAXRequest from '../libs/makeAJAXRequest';
import Form from './Form';

module.exports = class PageFeedback extends Form{
  constructor($form) {
    super($form);
    if (!($form instanceof HTMLFormElement)) return;
    this.$form = $form;
    this.$feedbackTitle = $form.querySelector('.c-page-feedback__comment__label');
    this.uid = getUniqueId();
    this.$form.querySelector('#page-feedback-rating-like').addEventListener('change', this.handlePageRating.bind(this));
    this.$form.querySelector('#page-feedback-rating-dislike').addEventListener('change', this.handlePageRating.bind(this));
    this.$form.addEventListener('submit', super.sendViaAjax.bind(this), false);

    document.addEventListener('DOMContentLoaded', this.getPrevPageRating.bind(this));
  }

  /**
   * Check if the page is rated before and set the correct radio option if so
   */
  getPrevPageRating() {
    if ('localStorage' in window && window['localStorage'] !== null) {
      const rating = localStorage.getItem(window.location.pathname);
      if (rating) {
        const ratedEl = this.$form.querySelector('#page-feedback-rating-' + rating);
        if (ratedEl) ratedEl.checked = true;
      }
    }
  }

  /**
   *
   * @param {Event} event
   */
  handlePageRating(event) {
    const rating = event.target.value;
    this.changeFeedbackTitle(rating);
    this.sendPageRating(rating);
    this.storePageRating(rating);
  }

  changeFeedbackTitle(rating) {
    this.$feedbackTitle.innerHTML = 'Waarom vond u deze informatie ' + (rating === 'dislike' ? 'niet ' : '') + 'zinvol?';
  }

  /**
   * Sent the page rating on change in the radio field
   *
   * @param {String} rating
   */
  sendPageRating(rating) {
    makeAJAXRequest(this.$form.action, {
      method: 'POST',
      data: {
        rating,
        url: window.location.href,
        id: this.uid,
      }
    })
    .then(() => {
      this.$form.querySelector('.c-page-feedback__comment').classList.remove('u-hidden');
    });
  }

  storePageRating(rating) {
    if ('localStorage' in window && window['localStorage'] !== null) {
      localStorage.setItem(window.location.pathname, rating);
    }
  }

  /**
   * Attach user data to the form data
   */
  getFormData() {
    const fields = super.getFormData();
    if (!fields) return false;
    fields.uid  =  this.uid;
    fields.url  = window.location.href;
    fields.clientBrowser = GetBrowser();
    fields.clientScreenSize = getScreenSize();
    fields.clientOS = getOS();
    return fields;
  }

  /**
   * Set the checked rating back after reset
   */
  resetForm() {
    const checkedRadio = [].find.call(this.$form.elements, el => el.name === 'page-feedback-rating' && el.checked);
    this.$form.reset();
    if (checkedRadio) checkedRadio.checked = true;
  }
}
