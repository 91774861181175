/**
 * CHARACTER COUNTER
 * *
 */
module.exports = class charCounter {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$inputField = $element;
    this.maxLength = parseInt(this.$inputField.getAttribute("maxlength"));

    if (!this.maxLength || this.maxLength < 1) return;
    this.createCounter();
    this.$inputField.addEventListener('keyup', this.renderCounter.bind(this));
  }

  createCounter() {
    this.$counter = document.createElement('DIV');
    this.$counter.className = 'form__help u-text-right';
    this.$inputField.parentNode.insertBefore(this.$counter, this.$inputField.nextSibling);
    this.renderCounter();
  }

  renderCounter() {
    const currentChars = this.$inputField.value.length;
    if (currentChars >= 1 && currentChars + 30 < this.maxLength) {
      this.$counter.className = 'form__help u-text-right is-success';
    } else if (currentChars + 30 > this.maxLength) {
      this.$counter.className = 'form__help u-text-right is-warning';
    } else {
      this.$counter.className = 'form__help u-text-right is-error';
    }
    this.$counter.innerText = currentChars + '/' + this.maxLength + ' tekens';
  }
}
