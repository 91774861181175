module.exports = class menuToggle {
  constructor($element) {
    this.$element = $element;
    this.$pageWrapper = document.querySelector('.o-page-wrapper');
    this.$menu = document.querySelector('.c-navigation-mobile');
    if (!($element instanceof HTMLElement) && !(this.$menu instanceof HTMLElement)) return;
    this.$element.addEventListener('click', this.openMenu.bind(this));
    // Also update the variable in navigation-menu.css when updating this one!
    this.navbreakpoint = 735;
  }

  closeOnClickOutside(event) {
    if (!this.$menu.contains(event.target)) this.closeMenu();
  }

  closeOnResize() {
    if (window.innerWidth > this.navbreakpoint) {
      this.closeMenu();
    }
  }

  closeOnKeypress(event) {
    // If delete or backspace
    if (event.keyCode == 27 || event.keyCode == 46) this.closeMenu();
  }

  openMenu() {
    document.body.classList.add('is-open-menu');
    this.$element.setAttribute('aria-expanded', 'true');

    // Add Close Event Listeners
    document.addEventListener('keydown', this.closeOnKeypress.bind(this), true);
    document.addEventListener('click', this.closeOnClickOutside.bind(this), true);
    window.addEventListener('resize', this.closeOnResize.bind(this), true);
  }

  closeMenu(event) {
    document.body.classList.remove('is-open-menu');
    this.$element.setAttribute('aria-expanded', 'false');

    // Remove close Event Listeners
    document.removeEventListener('keydown', this.closeOnKeypress);
    document.removeEventListener('click', this.closeOnClickOutside);
    window.removeEventListener('resize', this.closeOnResize);
  }
}