/**
 * Adds position sticky to the header when we scroll back
 */
module.exports = class scrollHeader {
	constructor($element) {
		if (!($element instanceof HTMLElement) || !window.requestAnimationFrame) return;

		this.$element = $element;
		this.previousTop = 0,
		this.currentTop = 0,
		this.scrollDelta = 10,
		this.scrollOffset = 150;
		this.scrolling = false;
		this.headerHeight = 200;

		window.addEventListener('scroll', () => {
			if (!this.scrolling) {
				this.scrolling = true;
				window.requestAnimationFrame(this.autoHideHeader.bind(this));
			}
		});

		window.addEventListener('resize', () => {
			this.$element.classList.remove('is-sticky');
		});
	}

	/**
	 * Shows and hide the header bases on position
	 */
	autoHideHeader() {
		const currentTop = window.pageYOffset;

		// Don't show sticky header when original header is in the screen
		if (currentTop < this.headerHeight) {
			this.$element.classList.remove('is-sticky');
			this.scrolling = false;
			return;
		}

		if (this.previousTop - currentTop > this.scrollDelta) {
			//if scrolling up...
			this.$element.classList.add('is-sticky');
		} else if (currentTop - this.previousTop > this.scrollDelta && currentTop > this.scrollOffset) {
			//if scrolling down...
			this.$element.classList.remove('is-sticky');
		}

		this.previousTop = currentTop;
		this.scrolling = false;
	}

}
