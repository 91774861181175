/**
 * Slider
 */
const lory = require('lory.js').lory;
module.exports = class Slider {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.autoplayIntervalDuration = 6000;
    this.$dotNavContainer = this.$element.querySelector('.c-slider__dot-nav');
    this.$liveRegion = this.$element.querySelector('.c-slider__live-region');
    this.keyUpListener = this.triggerKeyboardSlideChange.bind(this);
    this.slideCount = this.$element.querySelectorAll('.c-slider__slide').length;
    this.stopAutoplay = false;
    this.resizeLongTitles();
    // Only have keyboard event listeners on focus element
    this.$element.addEventListener('focus', () => document.addEventListener('keyup', this.keyUpListener), true);
    this.$element.addEventListener('blur', () => document.removeEventListener('keyup', this.keyUpListener), true);
    this.$element.addEventListener('after.lory.slide', this.notifyAccesibilityTools.bind(this));

    this.$element.addEventListener('after.lory.init', this.startAutoplay.bind(this));
    this.$element.addEventListener('mouseover', this.pauseAutoplay.bind(this));
    this.$element.addEventListener('click', this.pauseAutoplay.bind(this));
    this.$element.addEventListener('mouseout', this.unPauseAutoplay.bind(this));

    if (this.$dotNavContainer) {
      this.$element.addEventListener('after.lory.init', this.initDotNavigationEvents.bind(this));
      this.$element.addEventListener('after.lory.slide', this.toggleDotNavigationActiveClass.bind(this));
      this.$element.addEventListener('on.lory.resize', this.resetDotNavigationActiveClass.bind(this));
    }

    // Only create the instance when the CSS is applied otherwise the width of the frame is incorrect
    document.addEventListener('DOMContentLoaded', () => {
      this.instance = lory(this.$element, {
        infinite: 1,
        slideSpeed: 500,
        enableMouseEvents: true,
        classNameFrame: 'c-slider__frame',
        classNameSlideContainer: 'c-slider__slides'
      });
    });
  }

  /**
   * Init Dot Navigation items
   *
   * Add the click event listeners to the created items
   * @param {Event} e
   */
  initDotNavigationEvents(e) {
    for (let i = 0; i < this.slideCount; i++) {
      this.$dotNavContainer.children[i].addEventListener('click', (e) => {
        this.instance.slideTo(parseInt(e.target.innerText.slice(-1)) - 1);
        e.preventDefault();
      });
    }
  }

  /**
   * Toggle Dot Navigation Active Class
   *
   * @param {Event} e
   */
  toggleDotNavigationActiveClass(e) {
    for (let i = 0; i < this.slideCount; i++) {
      this.$dotNavContainer.children[i].classList.remove('is-active');
    }
    this.$dotNavContainer.children[e.detail.currentSlide - 1].classList.add('is-active');
  }

  /**
   * Reset Dot Navigation Active Class
   *
   * @param {Event} e
   */
  resetDotNavigationActiveClass(e) {
    for (let i = 0; i < this.slideCount; i++) {
      this.$dotNavContainer.children[i].classList.remove('is-active');
    }
    this.$dotNavContainer.children[0].classList.add('is-active');
  }

  /**
   * Notify Accesibility tools
   *
   * Set the focus to the current slide
   * Trigger live region update
   *
   * @param {Event} e
   */
  notifyAccesibilityTools(e) {
    const currentSlide = this.$element.querySelector('#slide-' + e.detail.currentSlide);
    if (currentSlide) {
      currentSlide.focus();
      this.updateLiveRegion(currentSlide);
    }
  }

  /**
   * Update Live Region
   *
   * Update the content of the ARIA live region DomElement with the current slide status
   * @param {Number} currentSlide
   */
  updateLiveRegion(currentSlide) {
    if (this.$liveRegion) {
      'Slide ' + currentSlide + ' van ' + this.slideCount;
    }
  }

  /**
   * Trigger Keyboard Slide Change
   *
   * Move a slide forward on keypress right arrow
   * Move a slide backward on keypress left arrow
   * @param {Event} event
   */
  triggerKeyboardSlideChange(event) {
    // left arrow
    if (event.keyCode == '37') {
      this.instance.prev();
      return;
    }
    // right arrow
    if (event.keyCode == '39') {
      this.instance.next();
      return;
    }
  }

  /**
   * Resize Long Titels
   *
   * Make the font-size of long titles smaller to prevent large content areas
   */
  resizeLongTitles() {
    const titles = this.$element.querySelectorAll('.c-card__content__title');
    for (let i = 0, len = titles.length; i < len; i++) {
      if (titles[i].innerText.length > 45 ) {
        titles[i].classList.add('c-card__content__title--small');
      }
    }
  }

  /**
   * Start Autoplay
   *
   * Set a setInterval to move to the next slide
   */
  startAutoplay() {
    this.autoplayTimeOut = setInterval(() => {
      if (!this.stopAutoplay) {
        this.instance.next();
      }
    }, this.autoplayIntervalDuration);
  }

  /**
   * Pause Autoplay
   *
   * Prevent moving to the next slide in autoplay
   */
  pauseAutoplay() {
    this.stopAutoplay = true;
  }

  /**
   * Unpause Autoplay
   *
   * Enable moving to the next slide in autoplay
   */
  unPauseAutoplay() {
    this.stopAutoplay = false;
  }
}