/**
 * Article
 *
 * List of functions on article pages
 *
 */
module.exports = class Article {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.isStoryLayout = true;
    this.$articleContent = this.$element.querySelector('.c-article__content');
    this.$sidebarElements = this.getArticleSidebarElements();
    this.fullImages = this.$articleContent.querySelectorAll('.u-media-fluid');
    this.updatedsidebarElements = [];
    this.init();
  }

  init() {
    window.addEventListener('load', this.maybeUpdateArticleSidebar.bind(this));
    window.matchMedia('(min-width: 980px)').addListener(this.maybeUpdateArticleSidebar.bind(this));
  }

  /**
   * Get the element that are in the sidebar
   *
   * Elements are pushed out of the main content
   * And set with position:absolute in the sidebar
   */
  getArticleSidebarElements() {
    const elementList = ['.s-extended-link__note', '.s-side-quote'];
    return this.$element.querySelectorAll(elementList.join(','));
  }

  /**
   * Only update when they are in the sidebar
   * This is on the desktop media query (980px)
   */
  maybeUpdateArticleSidebar() {
    if (window.matchMedia('(min-width: 980px)').matches) {
      this.calcSideberElementPositions();
      this.updateSidebarELementsPositions();
      this.UpdatePositionOverlappingImages();
    } else {
      // Set the margin bottom back to default
      this.$articleContent.removeAttribute('style');
    }
  }

  /**
   * Calc the positions of the sidebar elements
   *
   * The update is after the calculations of the offset of all the elements,
   * because getBoundingClientRect returns the wrong values after updating the elements 1 by 1
   *
   * 1. Walk through all of the sidebar Elements
   * 2. Create an object of the element with additional properties
   * 3. Check if the overlap
   * 4. If so, add that to the properties
   * 5. Return the calc elements array
   */
  calcSideberElementPositions() {
    const sidebarElements = this.$sidebarElements;
    const updatedsidebarElements = [];

    // Loop through all the items
    for (let index = 0, len = sidebarElements.length; index < len; index++) {
      const element = sidebarElements[index];

      // Create an object of the current item
      const currentElement = {
        el: element,
        offset: 0,
        top: element.getBoundingClientRect().top,
        bottom: element.getBoundingClientRect().bottom,
      };

      if (index !== 0) {
        // check If there is overlap with the previous element
        const prevEl = updatedsidebarElements[index - 1];
        const overlap = prevEl.bottom + prevEl.offset - currentElement.top;
        if (overlap > 0) {
          currentElement.offset = Math.ceil(overlap);
        }
      }
      updatedsidebarElements.push(currentElement);
    }
    this.updatedsidebarElements = updatedsidebarElements;
  }

  updateSidebarELementsPositions() {
    const newArticleOffset = this.newArticleOffset();
    if (newArticleOffset > 0 ) {
      this.$articleContent.style.marginBottom = Math.ceil(newArticleOffset) + 'px';
    }

    // The update is after the calculations of the offset of all the elements,
    // because getBoundingClientRect returns the wrong values after updating the elements 1 by 1
    this.updatedsidebarElements.forEach((item) => {
      if(item.offset > 0) {
        item.el.style.marginTop = item.offset + 'px';
      }
    });
  }

  /**
   * Push full width images with overlapping sidebar elements down
   */
  UpdatePositionOverlappingImages() {
    // Loop to every images
    for (let i = 0, len = this.fullImages.length; i < len; i++) {
      const image = this.fullImages[i];
      const topPosImage = image.getBoundingClientRect().top;
      // Check for every image if there is a sidebar element
      // With a top position above this image and a bottom position that is below the top position of the image
      for (let j = 0, jlen = this.updatedsidebarElements.length; j < jlen; j++) {
        const sideberEl = this.updatedsidebarElements[j];
        if ( (sideberEl.bottom + sideberEl.offset) > topPosImage && (sideberEl.top + sideberEl.offset) < topPosImage) {
          const overlap = sideberEl.bottom + sideberEl.offset - topPosImage;
          image.style.marginTop = Math.ceil(overlap) + 'px';
        }
      }
    }
  }

  /**
   * Check if two elements overlap
   *
   * @param {Node} topEl
   * @param {Node} bottomEl
   */
  getOverlapSize(topEl, bottomEl, addedOffset) {
    const topElBottom = topEl.getBoundingClientRect().bottom;
    const bottomElTop = bottomEl.getBoundingClientRect().top;
    return (topElBottom) - bottomElTop;
  }

  /**
   * Update the height of the article, so the sidebar won't overflow
   */
  newArticleOffset() {
    const lastSidebarElement = this.updatedsidebarElements[this.updatedsidebarElements.length - 1];
    if (!lastSidebarElement) return;
    const contentBottomPos = this.$articleContent.getBoundingClientRect().bottom;
    return lastSidebarElement.bottom + lastSidebarElement.offset - contentBottomPos;
  }
}