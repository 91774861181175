module.exports = class VideoLoader {
  constructor($element) {
    this.$element = $element;
    if (!($element instanceof HTMLElement)) return;
    this.$otherVideos = this.getOtherVideos();
    this.isLoading = false;
    this.$element.addEventListener('click', this.renderIframe.bind(this));
  }

  isYoutubeID(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length == 11) return match[2];
    return false;
  }

  isVimeoID(url) {
    const regExp = /^.*vimeo.com\/(\d+)($|\/)/;
    const match = url.match(regExp);
    if (match[1]) return match[1];
    return false;
  }

  getIframeId(url) {
    let id = this.isYoutubeID(url);
    if (id) return {
      source: 'youtube',
      id: id
    };

    id = this.isVimeoID(url);
    if (id) return {
      source: 'vimeo',
      id: id
    };

    console.error('Could not extract ID from video URL')
    return false;
  }

  createIframe(match) {
    if (match.source === 'youtube') {
      return this.createYoutubeIframe(match.id);
    }

    if (match.source === 'vimeo') {
      return this.createVimeoIframe(match.id);
    }

    return false;
  }

  createYoutubeIframe(id) {
    const iFrameContainer = document.createElement('div');
    iFrameContainer.setAttribute('class', 'c-card-video__container embed-responsive embed-responsive-16by9');
    const iFrame = `<iframe frameborder="0" allowfullscreen src="https://www.youtube.com/embed/${id}?rel=0&showinfo=0&autoplay=1">`;
    iFrameContainer.innerHTML = iFrame;
    return iFrameContainer;
  }

  createVimeoIframe(id) {
    const iFrameContainer = document.createElement('div');
    iFrameContainer.setAttribute('class', 'c-card-video__container embed-responsive embed-responsive-16by9');
    const iFrame = `<iframe frameborder="0" allowfullscreen src="http://player.vimeo.com/video/${id}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;color=ffffff&autoplay=1">`;
    iFrameContainer.innerHTML = iFrame;
    return iFrameContainer;
  }

  getOtherVideos() {
    const videos = document.querySelectorAll('[data-behaviour=VideoLoader]');
    if (!videos) return [];
    return [].filter.call(videos, video => video != this.$element);
  }

  stopOtherIframes() {
    [].forEach.call(this.$otherVideos, video => {
      video.classList.remove('is-loading');
      video.classList.remove('is-playing');
      const iFrameContainer = video.querySelector('.c-card-video__container');
      if (!iFrameContainer) return;
      iFrameContainer.parentElement.removeChild(iFrameContainer);
    });
  }

  renderIframe(event) {
    if (!this.isLoading) {
      this.isLoading = true;
      const target = event.currentTarget;
      const url = target.getAttribute('href');
      if (!url) return true;
      const match = this.getIframeId(url);
      if (!match) return true;
      const iFrame = this.createIframe(match);
      if (!iFrame) return true;

      this.stopOtherIframes();

      target.classList.add('is-loading');
      target.appendChild(iFrame);
      iFrame.addEventListener('load', () => {
        target.classList.remove('is-loading');
        target.classList.add('is-playing');
        this.isLoading = false;
      }, true);
    };
    event.preventDefault();
    event.stopPropagation();
  }
}