/**
 * Fetch external SVGs referenced in < use > elements when the browser itself fails to do so.
 * https: //github.com/Keyamoon/svgxuse
 */
require('svgxuse');

/*
 * Use ES6 Promise Syntax
 * https://developer.mozilla.org/nl/docs/Web/JavaScript/Reference/Global_Objects/Promise
 */
require('es6-promise/auto');

/*
 * Use URL Search Params
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
require('url-search-params-polyfill');

/*
 * Support for CSS object-fit
 * https: //github.com/bfred-it/object-fit-images
 */
var objectFitImages = require('object-fit-images');
objectFitImages();

/**
 * Element.closest()
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 */
if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector ||
  Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
  Element.prototype.closest = function (s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };

/* Support classList SVG Elements
 * https://github.com/Pomax/react-onclickoutside/issues/232
 */
if (!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: className => {
          return this.className.baseVal.split(' ').indexOf(className) !== -1
        },
      }
    },
  })
}

/**
 * Element Dataset
 * https: //developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset
 */
function elementDatasetPolyfill() {
  if (!document.documentElement.dataset &&
    (!Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'dataset') ||
      !Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'dataset').get
    )
  ) {
    const descriptor = {}

    descriptor.enumerable = true

    descriptor.get = function get() {
      const element = this
      const map = {}
      const attributes = this.attributes

      function toUpperCase(n0) {
        return n0.charAt(1).toUpperCase()
      }

      function getter() {
        return this.value
      }

      function setter(name, value) {
        if (typeof value !== 'undefined') {
          this.setAttribute(name, value)
        } else {
          this.removeAttribute(name)
        }
      }

      for (let i = 0; i < attributes.length; i += 1) {
        const attribute = attributes[i]

        // This test really should allow any XML Name without
        // colons (and non-uppercase for XHTML)

        if (attribute && attribute.name && (/^data-\w[\w-]*$/).test(attribute.name)) {
          const name = attribute.name
          const value = attribute.value

          // Change to CamelCase

          const propName = name.substr(5).replace(/-./g, toUpperCase)

          Object.defineProperty(map, propName, {
            enumerable: descriptor.enumerable,
            get: getter.bind({
              value: value || ''
            }),
            set: setter.bind(element, name)
          })
        }
      }
      return map
    }

    Object.defineProperty(HTMLElement.prototype, 'dataset', descriptor)
  }
}
elementDatasetPolyfill();

function initCustomEvent() {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined
    };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
};
initCustomEvent();