/**
 * Toggle
 *
 * Expands and collapses a content section on click
 *
 */
import {debounce} from '../libs/utils';

module.exports = class Toggle {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.$target = document.querySelector('#' + this.$element.getAttribute('aria-controls'));
    if (!(this.$target instanceof HTMLElement)) return;
    this.mediaQuery = $element.dataset.mediaQuery;
    this.toggleIsExpanded = false;
    this.collapsedText = this.$element.innerHTML;
    this.expandedText = this.$element.dataset.expandedText;
    this.isSlideToggle = this.$element.dataset.slide == 'true' || false;
    // Create a reference to the bind, to remove binded event listeners.
    // See https://stackoverflow.com/questions/11565471/removing-event-listener-which-was-added-with-bind
    this.clickListener = this.toggle.bind(this);

    if (this.mediaQuery != null) {
      this.maybeToggle();
      window.addEventListener('resize', debounce(() => this.maybeToggle(), 150));
    } else {
      this.setupToggle();
    }
  }

  /**
   * Add the event listener of the toggler
   */
  setupToggle() {
    this.$element.addEventListener('click', this.clickListener)
    if (this.isSlideToggle) {
      this.$target.classList.add('is-slide-toggle-target');
    }
  }

  /**
   * Toggle the toggler
   */
  toggle(event) {
    event.preventDefault();
    if (this.toggleIsExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
    this.toggleIsExpanded = !this.toggleIsExpanded;
  }

  expand() {
    if (this.isSlideToggle) {
      this.expandBySlide(this.$target);
    }
    this.$element.classList.add('has-expanded-content');
    this.$element.setAttribute('aria-expanded', 'true');
    this.$target.classList.remove('is-collapsed');
    if (this.expandedText) {
      this.$element.innerHTML = this.expandedText
    }
  }

  collapse(event) {
    var url = window.location.search;
    var cleanurl = url.replace('&expanded', '');
    history.pushState(null, document.title, cleanurl )
    if (this.isSlideToggle) {
      this.collapseBySlide(this.$target);
    }
    this.$element.classList.remove('has-expanded-content');
    this.$element.setAttribute('aria-expanded', 'false');
    this.$target.classList.add('is-collapsed');
    if (this.expandedText) {
      this.$element.innerHTML = this.collapsedText
    }
  }

  /**
   * Only listen for toggle events if screens fits the media query
   */
  maybeToggle() {
    if (window.matchMedia(`(${this.mediaQuery})`).matches) {
      this.setupToggle();
    } else {
      this.removeToggle();
    }
  }

  /**
   * Javascript driven collapsing
   *
   * CSS transitions are preferable, but for a true collapsing effect we need javascript
   *
   * @param {Element} element
   */
  collapseBySlide(element) {

    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.height = 0 + 'px';
      });
    });
  }

  /**
   * Javascript driven expanding
   *
   * CSS transitions are preferable, but for a true collapsing effect we need javascript
   *
   * @param {Element} element
   */
  expandBySlide(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';

    const transitionEndListener = function () {
      // remove this event listener so it only gets triggered once
      element.removeEventListener('transitionend', transitionEndListener);
      // remove "height" from the element's inline styles, so it can return to its initial value
      element.style.height = null;
    }

    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', transitionEndListener);
  }

  /**
   * Remove the event listener for the toggler
   */
  removeToggle() {
    this.$element.removeEventListener('click', this.clickListener)
  }
}