import makeAjaxRequest from '../libs/makeAJAXRequest';
import Awesomplete from 'awesomplete';
import jaroWinklerDistance from 'jaro-winkler';

/**
 * Add Typeahead functionallity via Awesomplete
 * https://leaverou.github.io/awesomplete/
 */
module.exports = class Autocomplete {
  constructor($input) {
    if (!($input instanceof HTMLElement) && $input.type !== 'INPUT') return;
    this.$input = $input;
    this.$searchSuggestionEndpoint = this.$input.dataset.suggestionEndpoint || false;
    this.cachedSuggestions = {};
    this.suggestionRequestTimeout = null;
    this.prevRequest = null;
    this.initAutocomplete();
  }

  /*
   * Match the result based on the Jared Winker Distance.
   * This algorithm calculates gives a scrore to the match between the input and the option.
   * The advantage of this instead of a basic preg_match is that it incooperates type errors.
   * For example football and footsall still have a good match score, a suggestion that wouldn't be picked op in preg match methods.
   *
   * See https: //github.com/jordanthomas/jaro-winkler/blob/master/index.js for the implementation
   * and https: //en.wikipedia.org/wiki/Jaro%E2%80%93Winkler_distance for the algorithm
  */
  initAutocomplete() {
    if (!this.$searchSuggestionEndpoint) return false;
    this.autocomplete = new Awesomplete(this.$input, {
      // Add the Jared Winker Distance.
      data(item, input) {
        return [item, jaroWinklerDistance(item, input, {
            caseSensitive: false
          })];
      },
      filter(item, input) {
        return item.value > 0.8;
      },
      sort(a,b){
        return b.value - a.value;
      },
      // Set the selected text as input value
      replace(text) {
        this.input.value = text;
      }
    });

    this.$input.addEventListener('input', this.getSearchSuggestions.bind(this));
  }


  /**
   * Get new search suggestions
   *
   * Previous search suggestions are cached, otherwise a new ajax call will be made
   */
  getSearchSuggestions(event) {
    const searchvalue = event.target.value;
    clearTimeout(this.suggestionRequestTimeout);
    // Don't make new request on the first strokes
    if (searchvalue.length < 4 ) return;

    /*
     * Wait 0,25s before fetching the request after the last key stroke
     * This prevents unnecessary requests when the user is typing the word
     */
    this.suggestionRequestTimeout = setTimeout(() => {
      /*
       * Load a previous cached suggestion if the user already searched for the searchvalue
       */
      if (searchvalue in this.cachedSuggestions) {
        this.autocomplete.list = this.cachedSuggestions[searchvalue];
        this.autocomplete.evaluate();
        return;
      }
      if (this.lastRequest) this.lastRequest.cancel();
      this.lastRequest = makeAjaxRequest(this.$searchSuggestionEndpoint + '?suggest=' + encodeURIComponent(searchvalue));
      this.lastRequest.then(response => {
        this.cachedSuggestions[searchvalue] = response.data;
        this.autocomplete.list = response.data;
        this.autocomplete.evaluate();
      })
    }, 250); // wait for quarter second.
  }
}