/**
 * Calendar
 *
 */
module.exports = class Calendar {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    // Add listener to parent and delegate events
    this.$element.addEventListener('click', this.toggleItem);
  }

  /**
   * Toggle the events
   */
  toggleItem(event) {
    // FIX IE11 where the use element of the SVG is the event target
    if (window.SVGElementInstance && event.target instanceof SVGElementInstance) {
      event.target = event.target.correspondingUseElement;
    }
    if (event.target.tagName.toLowerCase() === 'a') return true;
    const $item = event.target.closest('.c-card--event');

    if (!$item) return false;

    if ($item.classList.contains('is-expanded')) {
      if (event.target.classList.contains('c-card__toggle')) {
        $item.setAttribute('aria-expanded', 'false');
        $item.classList.remove('is-expanded');
      }
    } else {
      $item.setAttribute('aria-expanded', 'true');
      $item.classList.add('is-expanded');
    }
  }
}