import Autocomplete from './autocomplete';

/**
 * Search form
 *
 * Additional functionallity for the search form
 */module.exports = class SearchForm {
  constructor($element) {
    if (!($element instanceof HTMLElement)) return;
    this.$element = $element;
    this.$searchInput = this.$element.querySelector('.c-search-bar__search__input');
    this.$resetSearchButton = this.$element.querySelector('.c-search-bar__reset-search');

    // Listeners
    this.$element.addEventListener('submit', this.submit.bind(this));
    this.$searchInput.addEventListener('keyup', this.toggleSearchResetButton.bind(this));
    this.$resetSearchButton.addEventListener('click', this.resetSearch.bind(this));

    // Optional filters
    this.$filtersContainer = this.$element.querySelector('.c-search-bar__expanse');
    if (this.$filtersContainer) {
      this.$resetFiltersButton = this.$element.querySelector('.c-search-bar__reset-filters');
      this.$resetFiltersButton.addEventListener('click', this.resetFilters.bind(this));
    }

    this.removeDisabledProps();
    this.maybeExpandFilters();
    new Autocomplete(this.$searchInput);
    this.$searchInput.addEventListener('awesomplete-selectcomplete', () => this.$element.submit());
  }

  /**
   * Expand the filters sections when the param expaneded is added in the url
   */
  maybeExpandFilters() {
    var url = window.location.search;
    if (( url.indexOf('type') > -1 || url.indexOf('theme') > -1 || url.indexOf('date-start') > -1  || url.indexOf('date-end') > -1 ) && url.indexOf('expanded') == -1 ) {
      history.pushState(null, document.title, window.location.search + '&expanded' );
    }
    if (url.indexOf('expanded') > -1) {
      document.addEventListener('DOMContentLoaded', (event) => {
        const $expandToggle = this.$element.querySelector('.c-search-bar__expand-toggle');
        if ($expandToggle) $expandToggle.click();
      })
    }
  }

  removeDisabledProps() {
    // Remove previous set disabled attributes
    // https://www.billerickson.net/code/hide-empty-fields-get-form/
    [].forEach.call(this.$element.querySelectorAll('input, select'), (el) => {
      if (!el.value) el.removeAttribute("disabled");
    });
  }

  /**
   * Submit the form
   * Remove empty input fields
   * https: //www.billerickson.net/code/hide-empty-fields-get-form/
   */
  submit() {
    [].forEach.call(this.$element.querySelectorAll('input, select'), (el) => {
      if (!el.value) el.setAttribute('disabled', 'disabled');
    });
    return true; // ensure form still submits
  }

  /**
   * Reset filters
   *
   * @param {Event} event
   */
  resetFilters(event) {
    // Capture the search
    const searchQuery = this.$searchInput.value;
    // Reset the whole form
    this.$element.reset();
    // Set the search query back
    if (searchQuery !== '') {
      this.$searchInput.value = searchQuery;
    }
    event.preventDefault();
  }

  /**
   * Reset Search
   */
  resetSearch() {
    this.$searchInput.value = '';
    this.$resetSearchButton.style.display = 'none';
  }

  /**
   * Toggle Search Reset Button
   *
   * Only show the button if the search input has 1 or more characters
   * @param {Event} event
   */
  toggleSearchResetButton(event) {
    if (event.target.value !== '') {
      this.$resetSearchButton.style.display = 'block';
    } else {
      this.$resetSearchButton.style.display = 'none';
    }
  }
}