module.exports = class Header {
  constructor($element) {
    this.$element = $element;
    if (!($element instanceof HTMLElement)) return;
    this.$submenuLinks = Array.prototype.slice.call(this.$element.querySelectorAll('.c-sub-navigation__link, .c-menu-search__toggle'));
    this.$searchToggle = this.$element.querySelector('.c-menu-search__toggle');
    this.$searchToggle.addEventListener('openDropdown', this.hideSubmenuLinks.bind(this));
    this.$searchToggle.addEventListener('closeDropdown', this.showSubmenuLinks.bind(this));
  }

  hideSubmenuLinks() {
    this.$submenuLinks.forEach(link => link.classList.add('u-hidden'));
  }

  showSubmenuLinks() {
    this.$submenuLinks.forEach(link => link.classList.remove('u-hidden'));
  }
}